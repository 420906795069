[
  {
    "name": "osmosis",
    "ownerAddress": "osmovaloper1u5v0m74mql5nzfx2yh43s2tke4mvzghr6m2n5t",
    "maxPerDay": 1,
    "apiVersions": {
      "gov": "v1beta1"
    },
    "authzAminoExecPreventTypes": [
      "/cosmos.gov."
    ]
  },
  {
    "name": "juno",
    "ownerAddress": "junovaloper19ur8r2l25qhsy9xvej5zgpuc5cpn6syydmwtrt",
    "authzAminoExecPreventTypes": [
      "/cosmos.distribution.v1beta1.MsgWithdrawValidatorCommission"
    ]
  },
  {
    "name": "cosmoshub",
    "ownerAddress": "cosmosvaloper1a37ze3yrr2y9nn98l6frhjskmufvd40cpyd0gq"
  },
  {
    "name": "akash",
    "ownerAddress": "akashvaloper1xgnd8aach3vawsl38snpydkng2nv8a4kqgs8hf"
  },
  {
    "name": "chihuahua",
    "ownerAddress": "chihuahuavaloper19vwcee000fhazmpt4ultvnnkhfh23ppwxll8zz",
    "authzAminoExecPreventTypes": [
      "/cosmos.distribution.v1beta1.MsgWithdrawValidatorCommission"
    ]
  },
  {
    "name": "gravitybridge"
  },
  {
    "name": "regen",
    "ownerAddress": "regenvaloper1c4y3j05qx652rnxm5mg4yesqdkmhz2f6dl7hhk",
    "authzAminoExecPreventTypes": [
      "/cosmos.distribution.v1beta1.MsgWithdrawValidatorCommission"
    ]
  },
  {
    "name": "terra",
    "authzAminoLiftedValues": false
  },
  {
    "name": "terra2"
  },
  {
    "name": "sentinel"
  },
  {
    "name": "dig"
  },
  {
    "name": "bitcanna"
  },
  {
    "name": "emoney"
  },
  {
    "name": "kava"
  },
  {
    "name": "desmos",
    "authzAminoGenericOnly": false
  },
  {
    "name": "cryptoorgchain",
    "ownerAddress": "crocncl10mfs428fyntu296dgh5fmhvdzrr2stlaekcrp9",
    "authzAminoExecPreventTypes": [
      "/cosmos.distribution.v1beta1.MsgWithdrawValidatorCommission"
    ]
  },
  {
    "name": "evmos",
    "ownerAddress": "evmosvaloper1umk407eed7af6anvut6llg2zevnf0dn0feqqny",
    "txTimeout": 120000,
    "maxPerDay": 1,
    "gasPrice": "75000000000aevmos",
    "authzAminoExecPreventTypes": [
      "/cosmos.distribution.v1beta1.MsgWithdrawValidatorCommission"
    ]
  },
  {
    "name": "sifchain"
  },
  {
    "name": "lumnetwork"
  },
  {
    "name": "stargaze"
  },
  {
    "name": "comdex",
    "ownerAddress": "comdexvaloper17f70yjkvmvld379904jaddx9h0f74n32pjtmp6",
    "apiVersions": {
      "gov": "v1beta1"
    },
    "authzAminoExecPreventTypes": [
      "/cosmos.gov.",
      "/cosmos.distribution.v1beta1.MsgWithdrawValidatorCommission"
    ],
    "authzAminoGenericOnly": true
  },
  {
    "name": "cheqd"
  },
  {
    "name": "umee"
  },
  {
    "name": "bitsong"
  },
  {
    "name": "persistence"
  },
  {
    "name": "agoric"
  },
  {
    "name": "impacthub"
  },
  {
    "name": "kichain"
  },
  {
    "name": "sommelier"
  },
  {
    "name": "konstellation"
  },
  {
    "name": "fetchhub"
  },
  {
    "name": "cerberus"
  },
  {
    "name": "secretnetwork",
    "authzAminoGenericOnly": false
  },
  {
    "name": "bostrom"
  },
  {
    "name": "starname"
  },
  {
    "name": "rizon"
  },
  {
    "name": "decentr"
  },
  {
    "name": "assetmantle",
    "ownerAddress": "mantlevaloper1fqs7gakxdmujtk0qufdzth5pfyspus3yx394zd"
  },
  {
    "name": "crescent"
  },
  {
    "name": "meme"
  },
  {
    "name": "cronos",
    "enabled": false
  },
  {
    "name": "harpoon",
    "ownerAddress": "kujiravaloper1vue5lawr3s0au9zj0aqeft5aknx6cjq6w5ghca",
    "testnet": true
  },
  {
    "name": "kujira",
    "ownerAddress": "kujiravaloper1vue5lawr3s0au9zj0aqeft5aknx6cjq6w5ghca"
  },
  {
    "name": "genesisl1",
    "txTimeout": 120000
  },
  {
    "name": "tgrade",
    "enabled": false
  },
  {
    "name": "aioz",
    "enabled": false
  },
  {
    "name": "echelon"
  },
  {
    "name": "kichaintestnet",
    "testnet": true
  },
  {
    "name": "likecoin"
  },
  {
    "name": "passage",
    "ownerAddress": "pasgvaloper196rujtjehu0dfc7y85lkcaps6tel76g3l9knjy"
  },
  {
    "name": "stride",
    "ownerAddress": "stridevaloper1x2kta40h5rnymtjn6ys7vk2d87xu7y6zfu9j3r",
    "authzSupport": false
  },
  {
    "name": "pulsar",
    "testnet": true
  },
  {
    "name": "teritori",
    "ownerAddress": "torivaloper1d5u07lhelk6lal44a0myvufurvsqk5d499h9hz"
  },
  {
    "name": "rebus"
  },
  {
    "name": "jackal",
    "ownerAddress": "jklvaloper1rdyunvvqg2l3723hlyjvvsnkd4vg338uar8q2s"
  },
  {
    "name": "oraichain"
  },
  {
    "name": "acrechain"
  },
  {
    "name": "mars",
    "ownerAddress": "marsvaloper1hvtaqw9mlwc0a4cdx6g3klk8acfc6z3yazzk8a",
    "authzAminoExecPreventTypes": [
      "/cosmos.distribution.v1beta1.MsgWithdrawValidatorCommission"
    ]
  },
  {
    "name": "planq"
  },
  {
    "name": "injective",
    "ownerAddress": "injvaloper1vqz7mgm47xhx25xu5g9qagnz48naks6pk6fmg2",
    "authzAminoExecPreventTypes": [
      "/cosmos.distribution.v1beta1.MsgWithdrawValidatorCommission"
    ]
  },
  {
    "name": "xpla"
  },
  {
    "name": "kyve",
    "ownerAddress": "kyvevaloper1egqphd8yjdfv84fl825grwgna0pf2emagdmnz8",
    "authzAminoExecPreventTypes": [
      "/cosmos.distribution.v1beta1.MsgWithdrawValidatorCommission"
    ]
  },
  {
    "name": "quicksilver",
    "maxPerDay": 1,
    "authzAminoExecPreventTypes": [
      "/cosmos.distribution.v1beta1.MsgWithdrawValidatorCommission"
    ]
  },
  {
    "name": "chain4energy"
  },
  {
    "name": "coreum",
    "ownerAddress": "corevaloper1py9v5f7e4aaka55lwtthk30scxhnqfa6agwxt8"
  },
  {
    "name": "nois",
    "ownerAddress": "noisvaloper198un95fs7wxqkv53zhc8hjcyga8qgswtv82x9w"
  },
  {
    "name": "omniflixhub",
    "ownerAddress": "omniflixvaloper19d782trtcj4yq9lm7nxy3xs3xjg8vjktn3sjsd"
  },
  {
    "name": "empowerchain",
    "ownerAddress": "empowervaloper1msjwmclw77h5j0t69n7j2p9ur5ra46uxqaedxz",
    "authzAminoExecPreventTypes": [
      "/cosmos.distribution.v1beta1.MsgWithdrawValidatorCommission"
    ]
  },
  {
    "name": "qwoyn"
  },
  {
    "name": "neutron",
    "enabled": false
  },
  {
    "name": "dydx",
    "ownerAddress": "dydxvaloper15wphegl8esn7r2rgj9j3xf870v78lxg8yfjn95"
  },
  {
    "name": "celestia",
    "ownerAddress": "celestiavaloper1dsyuamue2p3l0z753pyrtegs52ftrg960re06j"
  }
]
